export const ENV = "PRODUCTION";
export const TELEMETRY_API_BASE = "https://telemetry.nugsdev.net";
export const AMPLITUDE = 1;
export const AMPLITUDE_API_KEY = "40bf50c8d456b7576c935332395a28f5";
export const LOG = 1;
export default {
  ENV,
  TELEMETRY_API_BASE,
  AMPLITUDE,
  AMPLITUDE_API_KEY,
  LOG
};